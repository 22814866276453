import * as React from 'react';
import { useRef, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Container,
  CssBaseline,
  Grid,
  Link,
  Paper,
  Typography,
  debounce,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  createStyles,
  createTheme,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import { AuthFormVariants, IAuthForm } from '../../SharedTypes';
import { SignUpFormInputs } from './components/SignUpFormInputs';
import { SuccessMessage } from './components/SuccessMessage';
import { PromptMessage } from './components/PromptMessage';
import { resendConfirmationEmail } from '../api/ResendConfirmationEmail';
import ReCAPTCHA from 'react-google-recaptcha';
import { trackReferral } from '../../util/trackReferral';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    formTitle: {
      fontWeight: 500,
    },
    formLink: {
      cursor: 'pointer',
      color: '#1E79CB',
    },
  })
);
const BASE_URL = 'https://app.mailgenius.com/users/edit';
const REDIRECT_URL = `${BASE_URL}?highlightedCode=true`;

export const SignUp = (props: IAuthForm) => {
  const classes = useStyles();
  const [usedEmail, setUsedEmail] = useState('');
  const [email, setEmail] = useState('');
  const [confirmationEmail, setConfirmationEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [captchaValue, setCaptchaValue] = useState<string>();
  const [isCaptchaInvalid, setIsCaptchaInvalid] = useState(true);
  const [signupError, setSignupError] = useState<string | null>(null);

  const theme = createTheme();

  const recaptcha = useRef<ReCAPTCHA>(null);

  const debouncedInputHandler = debounce(
    (setterFn: React.Dispatch<React.SetStateAction<string>>, value: string) => {
      setterFn(value);
      setSubmitted(false);
    },
    500
  );

  const handleFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value;
    debouncedInputHandler(setFirstName, value);
  };

  const handleLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value;
    debouncedInputHandler(setLastName, value);
  };

  const handleEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value.toLowerCase();
    debouncedInputHandler(setEmail, value);
  };

  const handleConfirmationEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value.toLowerCase();
    debouncedInputHandler(setConfirmationEmail, value);
  };

  const handlePhoneNumber = (phone: string) => {
    debouncedInputHandler(setPhoneNumber, phone);
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    const value = e.target.value;
    debouncedInputHandler(setPassword, value);
  };

  const handleCaptchaChange = () => {
    setIsCaptchaInvalid((prev) => !prev);
    setCaptchaValue(recaptcha && (recaptcha?.current?.getValue() ?? ''));
  };

  const handleResendConfirmationEmail = (email: string) => {
    resendConfirmationEmail(email).then(() => {
      props.onEmailResend && props.onEmailResend();
    });
  };

  const handleError = () => {
    recaptcha?.current?.reset();
    setCaptchaValue('');
    setIsCaptchaInvalid(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setErrors({});
    setSignupError(null);
    let token = document.querySelector(
      'meta[name="csrf-token"]'
    ) as HTMLMetaElement;

    axios
      .post(
        '/users.json',
        {
          sign_up: {
            first_name: firstName ? firstName : null,
            last_name: lastName ? lastName : null,
            phone_number: phoneNumber ? phoneNumber : null,
            email: email ? email : null,
            email_confirmation: confirmationEmail ? confirmationEmail : null,
            password: password ? password : null,
            password_confirmation: password ? password : null,
            remember_me: 0,
            redirect_to_extension_url: props.isFromExtension
              ? REDIRECT_URL
              : BASE_URL,
          },
          captcha_response: captchaValue ?? '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-Token': token.content,
          },
        }
      )
      .then((response) => {
        if (
          'error_code' in response.data &&
          response.data.error_code === 'previously_deleted_account'
        ) {
          handleError();
          setSignupError('User with this email already exists');
        } else {
          setUsedEmail(response.data.email);
          setCaptchaValue('');
          recaptcha?.current?.reset();
          setIsCaptchaInvalid(true);
          setSubmitted(true);
          trackReferral(response.data.email);
        }
      })
      .catch((error) => {
        handleError();
        if (error.response) {
          const errors = error.response.data.errors;
          setErrors(errors);
        }
      });
  };

  return (
    <Paper
      elevation={20}
      style={{ padding: '20px', maxWidth: '450px', margin: '20px auto' }}
    >
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          {props.promptText != null && !submitted ? (
            <PromptMessage message={props.promptText} />
          ) : (
            ''
          )}

          <Typography variant="subtitle1" align="center"></Typography>
          <CssBaseline />
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!submitted && (
              <Typography
                component="h1"
                variant="h5"
                className={classes.formTitle}
              >
                Create Free Account:
              </Typography>
            )}

            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ marginTop: 3 }}
            >
              {submitted ? (
                <SuccessMessage email={email} />
              ) : (
                <SignUpFormInputs
                  onHandleConfirmationEmail={handleConfirmationEmail}
                  onHandleEmail={handleEmail}
                  onHandleFirstName={handleFirstName}
                  onHandleLastName={handleLastName}
                  onHandlePassword={handlePassword}
                  onHandlePhoneNumber={handlePhoneNumber}
                  errors={errors}
                  messageButton={props.messageButton}
                  onCaptchaChange={handleCaptchaChange}
                  recaptchaRef={recaptcha}
                  isCaptchaInvalid={isCaptchaInvalid}
                />
              )}

              <Grid
                item
                style={{
                  marginTop: 20,
                  marginBottom: 2,
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                {submitted && (
                  <>
                    <Link
                      className={classes.formLink}
                      onClick={() => handleResendConfirmationEmail(email)}
                      variant="body2"
                      style={{}}
                    >
                      Didn't receive a confirmation email? Resend it.
                    </Link>
                    <br />
                  </>
                )}
                {signupError && (
                  <Typography color="error">{signupError}</Typography>
                )}
                <Link
                  className={classes.formLink}
                  onClick={() => props.onFormSwitch(AuthFormVariants.sign_in)}
                  variant="body2"
                  style={{}}
                >
                  Have an account? Sign in here.
                </Link>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Paper>
  );
};
