// Decoders
// using the naming convention "D<UppercaseInterfaceName>"
// example our Aspect Decoder would be called DAspect
import * as D from 'decoders';
import {
  AspectStatus,
  AuthFormVariants,
  MailTesterStatus,
  Severity,
} from './SharedTypes';

const DSeverity = D.oneOf(Object.keys(Severity));
const DMailTesterStatus = D.oneOf(Object.keys(MailTesterStatus));
const DAspectStatus = D.oneOf(Object.keys(AspectStatus));
const DAuthFormVariants = D.oneOf(Object.keys(AuthFormVariants));

const DFactor = D.exact({
  id: D.string,
  passing: D.boolean,
  status: DAspectStatus,
  severity: DSeverity,
  title: D.string,
  solution: D.string,
  points_deducted: D.number,
  name: D.string,
  passing_description: D.string,
});

const DAspect = D.exact({
  id: D.string,
  passing: D.boolean,
  status: DAspectStatus,
  severity: DSeverity,
  message: D.string,
  why_is_it_important: D.string,
  factors: D.array(DFactor),
  points_deducted: D.number,
});

const DMail = D.exact({
  rawHtmlBody: D.string,
  subjectLine: D.string,
  fromEmail: D.string,
  rawEmailWithHeaders: D.string,
});

// NOTE the JSON endpoint will be returning a subset of the MailTester interface. For example
// it won't be returning the currentTab key. What's the best way to deal with this?
const DMailTester = D.exact({
  status: DMailTesterStatus,
  isLoading: D.boolean,
  slug: D.string,
  id: D.number,
  createdAt: D.string,
  score: D.number,
  aspects: D.array(DAspect),
  mail: DMail,
});

const DMailTesterNotReady = D.exact({
  status: D.constant('NOT_READY'),
  slug: D.string,
});

const DMailTesterError = D.exact({
  status: D.constant('ERROR'),
  slug: D.string,
});

const DProductMetadata = D.exact({
  slugs_amount: D.string,
  features: D.array(D.string),
});

const DProduct = D.exact({
  id: D.string,
  name: D.string,
  metadata: DProductMetadata,
});

const DProductPrice = D.exact({
  id: D.string,
  unit_amount: D.number,
  recurring: D.string,
  product: DProduct,
  selected: D.nullable(D.boolean),
});

const DProductPrices = D.array(DProductPrice);

const DMailCurrentUser = D.exact({
  id: D.integer,
  email: D.string,
  first_name: D.string,
  last_name: D.string,
  phone_number: D.string,
  shared_on_fb: D.boolean,
  shared_on_lin: D.boolean,
  shared_on_tw: D.boolean,
  free_limit_reached: D.boolean,
  free_tests_popup_shown: D.boolean,
  role: D.string,
});

const DMailTesterOwner = D.exact({
  id: D.number,
  subject_line: D.nullable(D.string),
  email: D.nullable(D.string),
  slug: D.string,
  score: D.nullable(D.number),
  created_at: D.string,
  mailbox_email: D.string,
  status: D.nullable(DMailTesterStatus),
  automatic_email: D.either(D.string, D.null_)
});

const DMailTestersWrapper = D.array(DMailTesterOwner);
const DMailTestersMetaWrapper = D.exact({
  count: D.number,
});

const DMailAuthLimitsPlanWrapper = D.exact({
  forbidden: D.nullable(D.boolean),
});

const DUserLimitsPlanWrapper = D.exact({
  used_amount: D.number,
  total_amount: D.nullable(D.number),
  unlimited: D.boolean,
});

const DSubscriptionWrapper = D.exact({
  id: D.number,
  stripe_id: D.string,
  slugs_amount: D.nullable(D.number),
  unlimited: D.boolean,
  period_start: D.string,
  period_end: D.string,
  plan: D.string,
  status: D.string,
  invoice_status: D.string,
  canceled_at: D.nullable(D.string),
  canceled_at_period_end: D.boolean,
});

const DMailTesterLimit = D.exact({
  status: D.constant('LIMIT'),
  slug: D.string,
  forbidden: D.nullable(D.boolean),
});

const DMailTesterPaymentFailed = D.exact({
  status: D.constant('PAYMENT_FAILED'),
  slug: D.string,
});

const DMailTesterWrapper = D.either5(
  DMailTesterNotReady,
  DMailTesterError,
  DMailTesterLimit,
  DMailTesterPaymentFailed,
  DMailTester
);

const DMonitoredDomain = D.exact({
  id: D.number,
  active: D.boolean,
  admin_email: D.string,
  given_domain: D.string,
  parsed_domain: D.string,
  report_count: D.number,
  subscription_id: D.number,
  additional_domain: D.boolean,
});

const DMonitoredDomainMeta = D.exact({
  monitored_domains_count: D.number,
  pages: D.number,
  current_page: D.number,
  previous_page_url: D.string,
  next_page: D.string,
  first_page_url: D.string,
  last_page_url: D.string,
});

const DQuotaSubscriptions = D.exact({
  id: D.number,
  stripe_id: D.string,
  slugs_amount: D.nullable(D.number),
  period_start: D.string,
  period_end: D.string,
  status: D.string,
  unlimited: D.boolean,
  canceled_at: D.nullable(D.string),
  canceled_at_period_end: D.boolean,
  plan: D.string,
  invoice_status: D.string,
  quota_domains: D.maybe(D.array(DMonitoredDomain)),
});

const DQuotaSubscriptionsMetaResponse = D.exact({
  used_domains: D.number,
  domain_limit: D.number,
  monitored_domain_subscriptions: D.array(DQuotaSubscriptions),
});

const DQuotaSubscriptionsMetaUserEditResponse = D.exact({
  monitored_domain_subscriptions: D.array(DQuotaSubscriptions),
  monitored_domains: D.array(DMonitoredDomain),
});

const DMonitoredDomainResponse = D.exact({
  meta: DMonitoredDomainMeta,
  monitored_domains: D.array(DMonitoredDomain),
});

const DQuotaPriceProductMetadata = D.exact({
  features: D.array(D.string),
  slugs_amount: D.string,
});

const DQuotaPriceProduct = D.exact({
  id: D.string,
  name: D.string,
  metadata: DQuotaPriceProductMetadata,
});

const DQuotaPrice = D.exact({
  id: D.string,
  recurring: D.string,
  selected: D.boolean,
  unit_amount: D.number,
  product: DQuotaPriceProduct,
});

const DExtensionCode = D.string;

const DExtensionConnection = D.exact({
  id: D.number,
  created_at: D.string,
  extension_email: D.string,
  status: D.string,
  updated_at: D.string,
  user_id: D.number,
});

const DAutomaticEmail = D.exact({
  id: D.number,
  email: D.string,
  custom_slug: D.string,
  user_id: D.number,
  active: D.boolean,
  created_at: D.string,
  updated_at: D.string,
});

const DQuotaPriceResponse = D.array(DQuotaPrice);
const DExtensionConnectionResponse = D.array(DExtensionConnection);

export const DMailTesterGuard = D.guard(DMailTester);
export const DAuthFormVariantsGuard = D.guard(DAuthFormVariants);
export const DProductPricesGuard = D.guard(DProductPrices);
export const DMailTesterWrapperGuard = D.guard(DMailTesterWrapper);
export const DMailTestersMetaWrapperGuard = D.guard(DMailTestersMetaWrapper);
export const DMailCurrentUserGuard = D.guard(DMailCurrentUser);
export const DMailAuthLimitsPlanWrapperGuard = D.guard(
  DMailAuthLimitsPlanWrapper
);
export const DMailTestersGuard = D.guard(DMailTestersWrapper);
export const DUserLimitsPlanGuard = D.guard(DUserLimitsPlanWrapper);
export const DSubscriptionGuard = D.guard(DSubscriptionWrapper);
export const DMonitoredDomainGuard = D.guard(DMonitoredDomainResponse);
export const DQuotaSubscriptionsMetaGuard = D.guard(
  DQuotaSubscriptionsMetaResponse
);
export const DQuotaPriceGuard = D.guard(DQuotaPriceResponse);
export const DQuotaSubscriptionsMetaUserEditResponseGuard = D.guard(
  DQuotaSubscriptionsMetaUserEditResponse
);
export const DExtensionCodeGuard = D.guard(DExtensionCode);
export const DExtensionConnectionGuard = D.guard(DExtensionConnectionResponse);
export const DAutomaticEmailGuard = D.guard(D.nullable(DAutomaticEmail));
